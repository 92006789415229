
import BodyWrapOuter from '../wrappers/BodyWrapOuter.vue'
import JLinkMobileSVG from '@/components/svg/jumbolink/JLinkMobileSVG.vue'
import JLinkDesktopSVG from '@/components/svg/jumbolink/JLinkDesktopSVG.vue'
import bodytext from '@/mixins/bodytext'
export default {
  components: { BodyWrapOuter, JLinkDesktopSVG, JLinkMobileSVG },
  mixins: [bodytext],
  props: {
    section: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.$emit('sectionMounted')
  },
}
